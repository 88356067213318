import React, { useState } from "react"
import { useToken } from "../../../context/UseToken"
import { toast } from 'react-toastify';

function CSVUpload({ ruleId }) {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState(null)
  const { getToken } = useToken()

  const handleFileChange = async e => {
    const file = e.target.files[0]
    if (file) {
      const text = await file.text()
      parseCSV(text)
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    setUploadStatus(null)

    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/rules/words`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
          body: JSON.stringify({ data: rows }),
        }
      )

      if (response.ok) {
        setUploadStatus("Upload successful!")
      } else {
        const errorData = await response.json()
        setUploadStatus(`Error: ${errorData.error || "Failed to upload."}`)
        toast.error(errorData.error)
      }
    } catch (error) {
      setUploadStatus(`Error: ${error.message || "Failed to upload."}`)
      toast.error(errorData.message)
    }

    setIsLoading(false)
  }

  const parseCSV = text => {
    const lines = text.split("\n")
    const result = []

    // Skipping the header, so starting from 1
    for (let i = 1; i < lines.length; i++) {
      const row = lines[i].split(",").map(value => value.trim())

      if (row.length >= 1) {
        const word = row[0]
        const replacement = row[1] || null

        // Find an existing entry for the word
        const existingEntry = result.find(entry => entry.word === word)

        if (existingEntry) {
          // Only push the replacement if it's provided and not already in the replacements array
          if (
            replacement &&
            !existingEntry.replacements.includes(replacement)
          ) {
            existingEntry.replacements.push(replacement)
          } else if (replacement) {
            console.warn(
              `Duplicate replacement found for word "${word}": "${replacement}"`
            )
          }
        } else {
          // If word does not exist in the result, push a new entry
          result.push({
            word: word,
            replacements: replacement ? [replacement] : [],
            ruleId,
          })
        }
      }
    }
    setRows(result)
    console.log("Parsed CSV:", result)
  }

  return (
    <div>
      The current word set will be overwritten.
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleSubmit} disabled={isLoading || rows.length === 0}>
        Upload
      </button>
      {isLoading && <p>Uploading...</p>}
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  )
}

export default CSVUpload
