import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import colors from "../../../styles/colors"

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null

  return ReactDOM.createPortal(
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <CloseButton onClick={onClose}>x</CloseButton>
        </ModalHeader>
        {children}
      </ModalContainer>
    </ModalOverlay>,
    document.getElementById("modal-root")
  )
}

export default Modal

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;

  button,
  input,
  textarea {
    margin-top: 4px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    background: ${colors.green1};

    :hover {
      background-color: ${colors.green2};
    }
  }
`
const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

const CloseButton = styled.button`
  background: none !important;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  height: 30px !important;
  width: 30px !important;
  text-center;
  display:flex;
  justify-content:center;
  align-items:center;
  :hover {
    background-color: ${colors.green1} !important;
  }
`
