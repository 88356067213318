import React, { useState } from "react"
import styled from "styled-components"

function EditForm({ formData, setFormData, allowEdit }) {
  const hideFields = [
    "organizations",
    "organizationId",
    "complexConfig",
    "words",
    "rules",
    "categories",
    "id",
    "rulesetId",
    "categoryId",
    "ruleId",
  ]

  const getInputComponent = (key, value) => {
    // Todo: Actually hide this or something
    if (hideFields.includes(key)) {
      return //<input type="text" name={key} value={formData[key]} disabled />
    }

    if (typeof value === "boolean") {
      return (
        <InputField
          type="checkbox"
          style={{ width: "fit-content", minWidth: "20px" }}
          name={key}
          checked={formData[key]}
          onChange={handleChange}
          disabled={!allowEdit}
        />
      )
    }
    if (typeof value === "number") {
      return (
        <InputField
          type="number"
          name={key}
          value={formData[key]}
          onChange={handleChange}
          disabled={!allowEdit}
        />
      )
    }
    if (key === "ruleType") {
      return (
        <SelectField
          name={key}
          value={formData[key]}
          onChange={handleChange}
          disabled={!allowEdit}
        >
          <option value="EXCLUDE">Exclude</option>
          <option value="INCLUDE_AT_LEAST">Include At Least</option>
          <option value="INCLUDE_AT_MOST">Include At Most</option>
          <option value="COMPLEX">Custom complex rule</option>
          {/* You can add more options if you introduce more rule types */}
        </SelectField>
      )
    }

    if (key === "complexRuleType") {
      return (
        <SelectField
          name={key}
          value={formData[key]}
          onChange={handleChange}
          disabled={!allowEdit}
        >
          <option value="SPELLING">Spelling</option>
          <option value="LANGUAGELEVEL">Language Level</option>
          <option value="MAXCHARACTERS">Max characters</option>
          <option value="MINCHARACTERS">Min characters</option>
          <option value="BADSENTENCESPLIT">Bad sentence split</option>
          <option value="DISCOURSEMARKERS">Discourse marker</option>
          <option value="ALLCAPS">All caps</option>
          <option value="LLM">LLM Rule</option>
          <option value="MODERATION">LLM AI Moderation</option>
        </SelectField>
      )
    }

    if (key == "color") {
      return (
        <InputField
          type="color"
          name={key}
          value={formData[key]}
          onChange={handleChange}
          disabled={!allowEdit}
        />
      )
    }

    if (key == "description") {
      return (
        <TextArea
          name={key}
          value={formData[key]}
          onChange={handleChange}
          disabled={!allowEdit}
        />
      )
    }

    return (
      <InputField
        type="text"
        name={key}
        value={formData[key]}
        onChange={handleChange}
        disabled={!allowEdit}
      />
    )
  }

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    if (type === "checkbox") {
      setFormData(prevData => ({
        ...prevData,
        [name]: checked,
      }))
    } else if (type === "number") {
      setFormData(prevData => ({
        ...prevData,
        [name]: Number(value),
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  return (
    <form>
      {Object.keys(formData).map((key, index) =>
        hideFields.includes(key) ? null : (
          <InputWrapper key={key}>
            <InputLabel>
              {key.charAt(0).toUpperCase() + key.slice(1)}:
            </InputLabel>
            {getInputComponent(key, formData[key])}
          </InputWrapper>
        )
      )}
    </form>
  )
}

const InputField = styled.input`
  width: 250px;
  height: 35px;
  padding: 4px 10px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
`
const SelectField = styled.select`
  width: 250px;
  height: 35px;
  padding: 4px 10px;
  background-color: white;
  border: none;
  border-radius: 4px;
`

const TextArea = styled.textarea`
  width: 250px;
  padding: 4px 10px;
  background-color: white;
  border: none;
  border-radius: 4px;
`

const InputLabel = styled.label`
  color: black;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
`

const InputWrapper = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: grid;
  grid-template-columns: 140px 1fr;
`

export default EditForm
