import React, { useState } from "react"
import styled from "styled-components"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { darken, lighten } from "polished"

import Modal from "./Modal"
import EditForm from "./EditForm"
import { IconDelete, IconPen } from "../../icon"
import IconCheck from "../../icon/IconCheck"
import { useToken } from "../../../context/UseToken"
import { toast } from "react-toastify"
import colors from "../../../styles/colors"

function Accordion({
  level,
  children,
  endpoint,
  handleUpdate,
  handleDelete,
  data,
  name,
}) {
  const [isOpen, setIsOpen] = useState(false)
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false)
  const [formData, setFormData] = useState(data)
  const [editing, setEditing] = useState(false)

  const { getToken } = useToken()

  const handleDeleteClick = e => {
    e.stopPropagation()
    setIsDelModalOpen(true)
  }

  const handleDeleteConfirm = e => {
    e.stopPropagation()
    handleDelete(endpoint, data.id)
    closeDelModal()
  }

  const handleEditClick = e => {
    e.stopPropagation()
    setEditing(true)
    setIsOpen(true)
  }

  const handleSaveClick = e => {
    e.stopPropagation()
    setEditing(false)
    handleSave(formData)
  }

  const closeDelModal = () => {
    setIsDelModalOpen(false)
  }

  const handleSave = async updatedData => {
    console.log("Saving data:", updatedData)
    try {
      switch (endpoint) {
        case "ruleset":
          delete updatedData.categories
          break
        case "category":
          delete updatedData.rules
          break
        case "rule":
          delete updatedData.words
          delete updatedData.complexConfig
          break
        default:
          break
      }

      // Construct the URL
      const url = `${process.env.GATSBY_API_URL}/v1/rules/${endpoint}?id=${data.id}`

      // Send the PATCH request
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
        body: JSON.stringify({ data: updatedData }),
      })

      if (response.ok) {
        const result = await response.json()
        console.log("Update successful:", result)
        handleUpdate(endpoint, data.id, result)
      } else {
        const result = await response.json()
        if (result && result.error) {
          throw new Error(result.error)
        } else {
          throw new Error(`Error with the request. Status: ${response.status}`)
        }
      }
    } catch (error) {
      toast.error(error.message)
      throw error
    } finally {
      setEditing(false)
    }
  }

  return (
    <AccordionWrapper level={level}>
      <AccordionButton level={level} onClick={() => setIsOpen(!isOpen)}>
        <AccordionTitle color={data.color}>
          {isOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
          {data.name ?? name}
        </AccordionTitle>

        {!(endpoint == "ruleset" && data.name == "default") && (
          <ActionGroup>
            {editing ? (
              <EditButton onClick={handleSaveClick} allowEdit={editing}>
                <IconCheck size={20} color="grey" />
              </EditButton>
            ) : (
              <EditButton onClick={handleEditClick}>
                <IconPen size={20} color="grey" />
              </EditButton>
            )}

            <DeleteButton onClick={handleDeleteClick}>
              <IconDelete size={20} color={colors.red} />
            </DeleteButton>
          </ActionGroup>
        )}
      </AccordionButton>

      <AccordionContent level={level} open={isOpen}>
        <EditForm
          formData={formData}
          setFormData={setFormData}
          allowEdit={editing}
        />
        {children}
      </AccordionContent>

      <Modal isOpen={isDelModalOpen} onClose={closeDelModal}>
        <div>
          <p>Are you sure you want to delete this?</p>
          <button onClick={handleDeleteConfirm}>Yes, delete it.</button>
        </div>
      </Modal>
    </AccordionWrapper>
  )
}

export default Accordion

const AccordionWrapper = styled.div`
  margin: 8px 0px;
  width: 100%;
  border-radius: 4px;
  color: #000;
  background-color: ${props => darken(props.level * 0.05, "#fff")};
`

const AccordionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
  cursor: pointer;
  background: transparent;
  height: 50px;
`

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  margin-bottom: 0px;
  color: ${props => props.color ?? "#000"};
`

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const AccordionContent = styled.div`
  padding: 2px 16px;
  font-size: 14px;
  color: #666;
  display: ${props => (props.open ? "block" : "none")};
  p {
    margin-top: 0px; /* Adjust as needed */
    margin-bottom: 4px; /* Adjust as needed */
  }
`

const EditButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: grey;
  border-radius: 4px;
  padding: 5px 10px;

  :hover {
    color: ${colors.green2};
  }
`

const DeleteButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: grey;
  border-radius: 4px;
  padding: 5px 10px;
`

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  width: 20px;
  height: 20px;
  color: #000;
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 20px;
  height: 20px;
  color: #000;
`
