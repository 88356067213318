import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import colors from "../styles/colors"
import Accordion from "../components/pages/rules/Accordion"
import Modal from "../components/pages/rules/Modal"
import AddForm from "../components/pages/rules/AddForm"
import CSVUpload from "../components/pages/rules/CSVUpload"
import { darken } from "polished"
import useAuthenticationCheck from "../hooks/useAuthenticationCheck"
import { useUser } from "../context/UserContext"
import { useToken } from "../context/UseToken"
import { toast } from "react-toastify"
import PageHeading from "../components/PageHeading"

const endpoints = [
  "rulesets",
  "categories",
  "rules",
  "words",
  "complexRuleConfigs",
]

const RuleEditorPage = () => {
  useAuthenticationCheck()
  const { user } = useUser()
  const { getToken } = useToken()

  const [isModalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({ type: "", parentId: null })

  useEffect(() => {
    if (user) {
      fetchDataAll()
    }
  }, [user])

  const fetchDataAll = () => {
    endpoints.forEach(fetchData)
  }

  const fetchData = async endpoint => {
    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/rules/${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = await response.json()
      console.log(`Fetched ${endpoint}`, data)
      setState(prevState => ({ ...prevState, [endpoint]: data }))
    } catch (error) {
      console.error(`Error fetching ${endpoint}`, error)
    }
  }

  const [state, setState] = useState({
    rulesets: [],
    categories: [],
    rules: [],
    words: [],
    complexRuleConfigs: [],
  })

  const { rulesets, categories, rules, words, complexRuleConfigs } = state

  const initialDataMapping = {
    ruleset: { name: "" },
    category: { name: "", color: "", description: "" },
    rule: {
      name: "",
      language: "EN",
      shortDescription: "",
      description: "",
      weight: 1,
      isPremium: false,
      ruleType: "EXCLUDE",
      count: 1,
    },
    wordReplacement: { word: "", replacements: [] },
    complexRuleConfig: { complexRuleType: "LANGUAGELEVEL", config: "{}" },
  }

  const singularToPlural = {
    ruleset: "rulesets",
    category: "categories",
    rule: "rules",
    wordReplacement: "words",
    complexRuleConfig: "complexRuleConfigs",
  }

  const createItem = (type, parentId = null) => {
    console.log(`Creating ${type} with parentId ${parentId}`)
    console.log("Initial data:", initialDataMapping[type])
    setModalContent({ type, parentId, data: initialDataMapping[type] })
    setModalOpen(true)
  }

  const handleSave = async formData => {
    try {
      const requestBody = getRequestBody(modalContent, formData)
      console.log("Request body:", requestBody)
      const newData = await apiRequest("POST", modalContent.type, requestBody)
      updateState(modalContent.type, newData, "create")
      setModalOpen(false)
    } catch (error) {
      console.error(`Error saving ${modalContent.type}`, error)
    }
  }

  const handleUpdate = (type, id, updatedData) => {
    updateState(type, updatedData, "update", id)
  }

  const handleDelete = async (type, id) => {
    try {
      await apiRequest("DELETE", type, null, id)
      updateState(type, null, "delete", id)
    } catch (error) {
      console.error(`Error deleting ${type}`, error)
    }
  }

  const apiRequest = async (method, type, body = null, id = null) => {
    const url = id
      ? `${process.env.GATSBY_API_URL}/v1/rules/${type}?id=${id}`
      : `${process.env.GATSBY_API_URL}/v1/rules/${type}`

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
        body,
      })

      let result = await response.json()
      if (response.ok) {
        if (method === "POST") return result
      } else {
        if (result && result.error) {
          throw new Error(result.error)
        } else {
          throw new Error(`Error with the request. Status: ${response.status}`)
        }
      }
    } catch (error) {
      toast.error(error.message)
      throw error
    }
  }

  const updateState = (type, data, action, id = null) => {
    setState(prevState => {
      const pluralType = singularToPlural[type] || type
      let updatedData

      switch (action) {
        case "create":
          updatedData = [...prevState[pluralType], data]
          break
        case "update":
          updatedData = prevState[pluralType].map(item =>
            item.id === id ? data : item
          )
          break
        case "delete":
          updatedData = prevState[pluralType].filter(item => item.id !== id)
          break
        default:
          updatedData = prevState[pluralType]
          break
      }

      return { ...prevState, [pluralType]: updatedData }
    })
  }

  const getRequestBody = (modalContent, formData) => {
    const data = { ...formData }
    //const parentKey =
    //modalContent.type === "category" ? "rulesetId" : "categoryId"
    // switch statement for category, ruleset, rule, complexRuleConfig
    let parentKey = "rulesetId"
    if (modalContent.type === "category") {
      parentKey = "rulesetId"
    } else if (modalContent.type === "rule") {
      parentKey = "categoryId"
    } else if (modalContent.type === "complexRuleConfig") {
      parentKey = "ruleId"
    }
    if (modalContent.parentId) {
      data[parentKey] = modalContent.parentId
    }
    return JSON.stringify({ data })
  }

  return (
    <>
      <Helmet>
        <title>Codaco Rule Editor</title>
      </Helmet>
      <Centered>
        <Wrapper>
          <PageHeading>Codaco Rule Editor</PageHeading>

          {user ? (
            rulesets.length > 0 ? (
              <>
                <SectionHeader level={1}>
                  Rulesets
                  <AddItemButton onClick={() => createItem("ruleset")}>
                    Add rule set
                  </AddItemButton>
                </SectionHeader>

                {rulesets.length &&
                  rulesets.map((ruleset, i) => (
                    <Accordion
                      level={0}
                      key={`rs-${i}`}
                      endpoint="ruleset"
                      data={ruleset}
                      handleUpdate={handleUpdate}
                      handleDelete={handleDelete}
                    >
                      <SectionHeader level={0}>
                        Categories
                        <AddItemButton
                          onClick={() => createItem("category", ruleset.id)}
                        >
                          Add category
                        </AddItemButton>
                      </SectionHeader>

                      {categories.length &&
                        categories
                          .filter(category => category.rulesetId === ruleset.id)
                          .map((category, j) => (
                            <Accordion
                              level={1}
                              key={`cat-${j}`}
                              data={category}
                              endpoint="category"
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            >
                              <SectionHeader level={1}>
                                Rules
                                <AddItemButton
                                  onClick={() =>
                                    createItem("rule", category.id)
                                  }
                                >
                                  Add Rule
                                </AddItemButton>
                              </SectionHeader>

                              {rules.length &&
                                rules
                                  .filter(
                                    rule => rule.categoryId === category.id
                                  )
                                  .map((rule, k) => (
                                    <Accordion
                                      level={2}
                                      key={`rule-${k}`}
                                      data={rule}
                                      endpoint="rule"
                                      handleUpdate={handleUpdate}
                                      handleDelete={handleDelete}
                                    >
                                      {(rule.ruleType === "INCLUDE_AT_LEAST" ||
                                        rule.ruleType ===
                                          "INCLUDE_AT_MOST") && (
                                        <p>Count: {rule.count}</p>
                                      )}

                                      {rule.ruleType === "COMPLEX" && (
                                        <>
                                          {complexRuleConfigs.filter(
                                            complexConfig =>
                                              complexConfig.ruleId === rule.id
                                          ).length === 0 && (
                                            <SectionHeader level={2}>
                                              Complex configuration
                                              <AddItemButton
                                                onClick={() =>
                                                  createItem(
                                                    "complexRuleConfig",
                                                    rule.id
                                                  )
                                                }
                                              >
                                                Add
                                              </AddItemButton>
                                            </SectionHeader>
                                          )}

                                          {complexRuleConfigs
                                            .filter(
                                              complexConfig =>
                                                complexConfig.ruleId === rule.id
                                            )
                                            .map((complexConfig, l) => (
                                              <React.Fragment
                                                key={`complexConfig-${l}`}
                                              >
                                                <Accordion
                                                  level={3}
                                                  data={complexConfig}
                                                  name="Complex rule config"
                                                  endpoint="complexRuleConfig"
                                                  handleUpdate={handleUpdate}
                                                  handleDelete={handleDelete}
                                                ></Accordion>
                                              </React.Fragment>
                                            ))}
                                        </>
                                      )}

                                      {/* Only render wordlist if ruleType is not COMPLEX */}
                                      {rule.ruleType !== "COMPLEX" && (
                                        <>
                                          <SectionHeader level={2}>
                                            Word List
                                            <AddItemButton
                                              onClick={() =>
                                                createItem("csvUpload", rule.id)
                                              }
                                            >
                                              Upload CSV
                                            </AddItemButton>
                                          </SectionHeader>
                                          {words
                                            .filter(
                                              wordItem =>
                                                wordItem.ruleId === rule.id
                                            )
                                            .map(wordItem => (
                                              <WordReplacement
                                                key={wordItem.id}
                                              >
                                                <strong>
                                                  {" "}
                                                  {wordItem.word}
                                                </strong>
                                                {wordItem.replacements.length
                                                  ? ":"
                                                  : ""}{" "}
                                                {wordItem.replacements.join(
                                                  ", "
                                                )}
                                              </WordReplacement>
                                            ))}
                                        </>
                                      )}
                                    </Accordion>
                                  ))}
                            </Accordion>
                          ))}
                    </Accordion>
                  ))}
                <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                  {modalContent.type === "csvUpload" ? (
                    <CSVUpload ruleId={modalContent.parentId} />
                  ) : (
                    <AddForm
                      data={initialDataMapping[modalContent.type]}
                      onSave={handleSave}
                    />
                  )}
                </Modal>
              </>
            ) : (
              <div>
                No rulesets found or you have no permission to access this
                content
              </div>
            )
          ) : (
            <div>Loading</div>
          )}
          <div id="modal-root"></div>
        </Wrapper>
      </Centered>
    </>
  )
}

const WordReplacement = styled.div`
  border-radius: 4px;
  display: inline-block;
  background: #ddd;
  margin: 4px 8px;
  padding: 4px 8px;
`

const AddItemButton = styled.div`
  color: gray;
  border: 1px solid lightgray;
  font-size: 14px;
  border-radius: 5px;
  padding: 3px 10px;
  cursor: pointer;

  &:hover {
    color: black;
    border-color: black;
  }
`

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const Wrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`

const SectionHeader = styled.div`
  display: flex;
  gap: 20px;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 10px;
  color: black;

  button {
    outline: none;
    // shadow: none;
    border: 1px solid ${props => darken((props.level + 2) * 0.05, "#fff")};
    border-radius: 3px;
    background: ${props => darken((props.level + 1) * 0.05, "#fff")};
    :hover {
      background: ${colors.green2};
      color: white;
    }
  }
`

export default RuleEditorPage
