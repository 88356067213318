import React from "react"
import { IIconProps as IProps } from "./IconProps"

const IconCheck = (props: IProps) => (
    <svg
        style={{
            width: `${props.size}px`,
            height: `${props.size}px`,
        }}
        viewBox="0 0 16 12"
        fill="none"
        stroke={props.color}
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
    </svg>
)

export default IconCheck
