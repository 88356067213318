import React, { useState } from 'react';
import EditForm from './EditForm';

function AddForm({ data, onSave }) {
    const [formDataOuter, setFormDataOuter] = useState(data);

    const [trueVar, setTrueVar] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formDataOuter);
    };
    return (
        <>
            <EditForm formData={formDataOuter} setFormData={setFormDataOuter} allowEdit={trueVar} />
            <button onClick={handleSubmit}>
                Save
            </button>
        </>
    );
}


export default AddForm;
