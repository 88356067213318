import React from "react"
import styled from "styled-components"
import colors from "../styles/colors"

export default styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: ${colors.green2};
  margin-bottom: 20px;
  width: 100%;
`
